import React from "react"
import { Link } from "gatsby"

const SupportiveCareFooterLinks = () => {
  return (
    <div className="row row--inner align-middle">
      <div className="columns">
        <p>Learn about the signs and symptoms of other common side effects of treatment and how the healthcare team may manage each. </p>
        <div className="row">
          <div className="columns small-12 large-6 link-list">
            <Link activeClassName="visually-hidden" partiallyActive={true} to="/supportive-care/nausea-vomiting/">Nausea and Vomiting &#8250;</Link>
            <Link activeClassName="visually-hidden" partiallyActive={true} to="/supportive-care/constipation-diarrhea/">Constipation and Diarrhea &#8250;</Link>
            <Link activeClassName="visually-hidden" partiallyActive={true} to="/supportive-care/diet-nutrition/">Decreased Appetite and Nutrition &#8250;</Link>
            <Link activeClassName="visually-hidden" partiallyActive={true} to="/supportive-care/blood-counts/">Blood Counts &#8250;</Link>
          </div>
          <div className="columns small-12 large-6 link-list">
            <Link activeClassName="visually-hidden" partiallyActive={true} to="/supportive-care/hair-loss/">Hair Loss &#8250;</Link>
            <Link activeClassName="visually-hidden" partiallyActive={true} to="/supportive-care/mouth-sores/">Mouth Sores &#8250;</Link>
            <Link activeClassName="visually-hidden" partiallyActive={true} to="/supportive-care/pain/">Pain &#8250;</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportiveCareFooterLinks
