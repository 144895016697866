import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import SupportiveCareSubmenu from "../../components/template-partials/supportive-care/supportive-care-submenu"
import SupportiveCareFooterLinks from "../../components/template-partials/supportive-care/supportive-care-footer-links"
import headingBg from "../../images/4.0-headerbg.jpg"
import headingRound from "../../images/supportive-care-nav-image.jpg"
import AlertInfoCard from "../../components/template-partials/global-components/alert-infocard"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"


import sore from "../../images/4.7-sore.jpg";
import mouthwash from "../../images/4.7mouthwash.jpg"
import drinkStraw from "../../images/4.7_drinkstraw.jpg"
import roomTemp from "../../images/4.7_roomtempfood.jpg"
import pureedFood from "../../images/4.7_pureedfood.jpg"
import smallPeices from "../../images/4.7_eatsmallpieces.jpg"
import spicyFoods from "../../images/4.7_avoidspicyacidic.jpg"
import crunchyFoods from "../../images/4.7_avoidcrunchy.jpg"

import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

const MouthSoresPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: 'unslick' },
      { breakpoint: 1024, settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }},
    ]
  };
  return (
    <Layout>
      <SEO title="Neuroblastoma Supportive Care | Mouth Sores" description="Some neuroblastoma therapies and medications may cause mouth sores known as mucositis. Learn more about the signs and symptoms, as well how to manage them." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Mouth Sores (Mucositis)">
        <h1 className="section__heading section__heading--green h1">Mouth Sores (Mucositis)</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Supportive Care`}>
              <SupportiveCareSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="tc-barney h2 jost-med">Potential causes of mucositis</h2>
                <p>Some <Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">chemotherapy</Link> medications and radiation therapy can cause swelling and irritation of the lining of the mouth, esophagus, stomach, intestines, and anus. This irritation may lead to sores, also known as <Link rel="glossary" to="/support-and-community/glossary/#mucositis">mucositis</Link>.</p>
                <h3 className="tc-viridian h4 jost-med">Signs and symptoms of mucositis</h3>
                <p>Your child may have mucositis if:</p>
                <ul className="ul--dotted">
                  <li>The inside of their mouth appears red, with sores that are open and painful</li>
                  <li>They drool or have difficulty swallowing</li>
                  <li>They do not want to eat or drink</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 align-middle">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Treating mucositis</h4>
            <p>Unfortunately, there is no medicine available to make mouth sores go away. Your child’s healthcare team may prescribe a special mouthwash as part of their oral routine, along with pain medication to help with any pain your child may have.</p>
          </div>
          <div className="columns small-12 large-4 text-center large-text-left">
            <FancyImage url={sore} alt="Mouth sores may occur during your child’s neuroblastoma treatment."/>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-3">
          <div className="columns">
            <h3 className="tc-viridian h4 jost-med">Managing mucositis at home</h3>
            <p>To help prevent and manage mucositis for your child, you can help by having them:</p>
          </div>
        </div>
        <div className="row row--inner mt-4 mb-4">
          <div className="columns">
            <Slider className="grid-slider grid-slider--large-4" {...settings}>
              <div className="text-center">
                <img src={drinkStraw} alt="" style={{maxWidth:`50px`}}/>
                <p className="mt-2">Drink plenty of fluids (through a straw if more comfortable)</p>
              </div>
              <div className="text-center">
                <img className="lg_mt-1" src={roomTemp} alt="" style={{maxWidth:`140px`}}/>
                <p className="mt-2">Eat foods that are cold or at room temperature</p>
              </div>
              <div className="text-center">
                <img className="lg_mt-1" src={pureedFood} alt="" style={{maxWidth:`110px`}}/>
                <p className="mt-2">Eat soft, pureed, or blended foods</p>
              </div>
              <div className="text-center">
                <img className="lg_mt-2" src={smallPeices} alt="" style={{maxWidth:`170px`}}/>
                <p className="mt-2">Eat food in small pieces</p>
              </div>
              <div className="text-center">
                <img src={spicyFoods} alt="" style={{maxWidth:`100px`}}/>
                <p className="mt-2">Avoid spicy or acidic foods</p>
              </div>
              <div className="text-center">
                <img src={crunchyFoods} alt="" style={{maxWidth:`100px`}}/>
                <p className="mt-2">Avoid dry or crunchy foods</p>
              </div>
              <div className="text-center">
                <img src={mouthwash} alt="" style={{maxWidth:`100px`}}/>
                <p className="mt-2">Avoid mouthwash that contains alcohol</p>
              </div>
            </Slider>
          </div>
        </div>
        <AlertInfoCard className="lg_mt-2 lg_mb-2 mt-2 mb-2" text="Please call your healthcare team if your child is unable to drink fluids, has difficulty swallowing, or if their pain medicine does not seem to be helping. "/>
        <SupportiveCareFooterLinks/>
      </Section>

      <InteriorPageFooter>

      <UpNextCard topic="Pain" title="Pain during treatment" lead="Learn how pain is managed during treatment." url="/supportive-care/pain/" />

      </InteriorPageFooter>

    </Layout>
  )
}

export default MouthSoresPage
