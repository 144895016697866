import React from "react"
import { Link } from "gatsby"

const SupportiveCareSubmenu = () => {
  return (
    <>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Supportive Care Overview" activeClassName="isActive" to="/supportive-care/">Supportive Care Overview <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Neusea and Vomiting" activeClassName="isActive" partiallyActive={true} to="/supportive-care/nausea-vomiting/">Nausea and Vomiting <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Constipation and Diarrhea" activeClassName="isActive" partiallyActive={true} to="/supportive-care/constipation-diarrhea/">Constipation and Diarrhea <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Decreased Appetite and Nutrition" activeClassName="isActive" partiallyActive={true} to="/supportive-care/diet-nutrition/">Decreased Appetite and Nutrition <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Blood Counts" activeClassName="isActive" partiallyActive={true} to="/supportive-care/blood-counts/">Blood Counts <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Hair Loss" activeClassName="isActive" partiallyActive={true} to="/supportive-care/hair-loss/">Hair Loss <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Mouth Sores" activeClassName="isActive" partiallyActive={true} to="/supportive-care/mouth-sores/">Mouth Sores <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Pain" activeClassName="isActive" partiallyActive={true} to="/supportive-care/pain/">Pain <span className="np-arrow">&rsaquo;</span></Link></li>
    </>
  )
}

export default SupportiveCareSubmenu
