import React from "react"
import * as SassVars from '../../../components/variables.module.scss';
import PropTypes from "prop-types"
import alert from "../../../images/ALERT.png";

const AlertInfoCard = (props) => {
  return (
    <div className={`row row--inner align-middle align-center small-col-reverse ` + props.className}>
      <div className="columns">
        <div className="card card--no-pad" style={{borderColor: SassVars.barney, minHeight: 0 }}>
          <div className="row align-middle">
            <div className="columns large-2 small-12 text-center">
              <img src={alert} style={{maxWidth:`111px` }} width="100%" height="auto" alt={props.text}/>
            </div>
            <div className="columns">
              <p className="no-pad p--std h5"><strong>{props.text}</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AlertInfoCard.defaultProps = {
  text: 'PLESE ENTER A TEXT PROPERTY',
  link: '/'
};

AlertInfoCard.propTypes = {
  text: PropTypes.node.isRequired,
  link: PropTypes.node.isRequired
}

export default AlertInfoCard
